<template>
  <div class="event-details" v-if="pageLoaded">
    <div class="float-right" v-if="eventSubmission !== null">
      <b-button size="sm" class="mr-1" v-bind:variant="eventSubmission === CalendarStatusEnum.REJECTED ? 'danger' : `outline-danger`" @click="responseEvent(CalendarStatusEnum.REJECTED)">{{$t('calendar.action.reject')}}</b-button>
      <b-button size="sm" v-bind:variant="eventSubmission === CalendarStatusEnum.APPROVED ? 'success' : 'outline-success'" @click="responseEvent(CalendarStatusEnum.APPROVED)">{{$t('calendar.action.accept')}}</b-button>
    </div>
    <div>
      <!-- Header: Administrative Info -->
      <div class="d-flex my-1">
        <feather-icon icon="CalendarIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ $t('calendar.details') }}
        </h4>
      </div>
      <hr/>
      <b-row class="">
        <b-col cols="1" v-b-tooltip.hover :title="$t('calendar.start')">
          <i class="far fa-clock"></i>
        </b-col>
        <b-col cols="5">
          <p class="capitalize">{{ event.start }}</p>
        </b-col>
        <b-col cols="1" v-b-tooltip.hover :title="$t('calendar.end')">
          <i class="fas fa-clock"></i>
        </b-col>
        <b-col cols="5">
          <p class="capitalize">{{ event.end }}</p>
        </b-col>
        <b-col cols="1" v-b-tooltip.hover :title="$t('calendar.host')">
          <i class="far fa-user"></i>
        </b-col>
        <b-col cols="5">
          <p class="capitalize">{{ getUserFullName(event.userId) }}</p>
        </b-col>
        <b-col cols="1" >
          <i class="far fa-calendar"></i>
        </b-col>
        <b-col cols="5">
          <p class="capitalize" v-if="event.type">
            <b-alert :variant="$t(`calendarTypes.${event.type}.color`)" show>
            {{ $t(`calendarTypes.${event.type}.name`)}}
            </b-alert>
          </p>

        </b-col>
        <b-col cols="1" >
          <i class="fa-regular fa-building"></i>
        </b-col>
        <b-col cols="5">
          <p class="capitalize">{{ getOrganizationNameById(event.orgId) }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <template v-if="event.location != null && event.location.trim().length > 0">
          <b-col cols="1" v-b-tooltip.hover :title="$t('calendar.location')">
            <i class="fas fa-map-marker-alt"></i>
          </b-col>
          <b-col :cols=" event.url != null && event.url.trim().length > 0 ? 5 : 10">
            <p class="capitalize">{{ event.location }}</p>
          </b-col>
        </template>
        <template v-if="event.url != null && event.url.trim().length > 0">
          <b-col cols="1" v-b-tooltip.hover :title="$t('calendar.url')">
            <i class="fas fa-at"></i>
          </b-col>
          <b-col :cols=" event.location != null && event.location.trim().length > 0 ? 5 : 10">
            <b-link :href="event.url" target="_blank" class="text-lowercase">{{ event.url }}</b-link>
          </b-col>
        </template>
      </b-row>

      <div class="mt-2" v-if="event.users != null && event.users.length > 0">
        <!-- Header: User Info -->
        <div class="d-flex my-1">
          <feather-icon icon="UsersIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ $t('calendar.representatives') }}
          </h4>
        </div>
        <hr/>
        <div>
          <div class="capitalize">
            <b-avatar-group  >
              <user-avatar badge v-for="user in event.users" v-bind:key="user.userId" v-bind:badge-variant="badgeVariant(user)"
                           :user-id="user.userId" :size="30">

              </user-avatar>
            </b-avatar-group>
          </div>
        </div>
      </div>


      <div class="" v-if="hasClients">
        <!-- Header: Client Info -->
        <div class="d-flex my-1">
          <i class="fas fa-users" style="font-size: 19px"></i>
          <h4 class="mb-0 ml-50">
            {{ $t('calendar.clients') }}
          </h4>
        </div>
        <hr/>
        <div class="capitalize" >
          <b-avatar-group v-if="loading.clients !== true">
            <b-avatar button  class="pull-up" size="sm" variant="primary"
                       v-for="client in clients" v-bind:key="client.id"
                       :text="client.acronym"
                       v-b-tooltip :title="client.name"
                       @click="onClientSelect(client.id)"
            ></b-avatar>
          </b-avatar-group>
          <b-avatar-group v-else>
            <b-skeleton v-for="client in event.clientsIds" v-bind:key="client" type="avatar" @click="onClientSelect(client)"></b-skeleton>
          </b-avatar-group>
        </div>
      </div>
    </div>
    <!-- Header: Administrative Info -->
    <div class="d-flex my-1" v-if="event.body != null && event.body.trim().length > 0">
      <feather-icon icon="AlignJustifyIcon" size="19"/>
      <h4 class="mb-0 ml-50">
        {{ $t('calendar.description') }}
      </h4>
    </div>
    <hr/>
    <div v-if="event.body != null && event.body.trim().length > 0">
      <p class="">{{ event.body }}</p>
    </div>
  </div>
  <div class="event-details" v-else-if="error !== ''">
    <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{ error }}</strong>
    </b-alert>
  </div>
  <div class="event-details" v-else>
    Loading
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import UserAvatar from '@/components/widget/UserAvatar'
import mixinBase from '@/components/mixin/mixinBase'
import { CalendarStatusEnum } from '@/model/calendar/CalendarStatusEnum'
import { isEmptyList } from '@/model/Utils'

export default {
  name: 'calendarEventBasic',
  mixins: [mixinBase],
  components: { UserAvatar },
  data: () => ({
    CalendarStatusEnum,
    loading: { event:true, clients: true },
    error: '',
    clients: [],
    //Listeners
    listeners: { load: () => {} }
  }),
  created() {
    this.reloadClients();
  },
  props: {
    event: Object,
    external: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      data: function (store) {
        return store.data
      }
    }),
    ...mapGetters('user',['myId']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms', 'getOrganizationNameById']),
    isExternal() {
      return this.external === true
    },
    hasClients() {
      return this.event.clientsIds != null && this.event.clientsIds.length > 0
    },
    hasOnlyOneClient() {
      return this.hasClients && this.event.clientsIds.length === 1
    },
    uniqueClient() {
      if (!this.hasOnlyOneClient) {
        return {}
      }
      return this.clients[0]
    },
    pageLoaded() {
      return this.event != null && typeof this.event === 'object'
    },
    eventColor() {
      if (this.event == null || this.event.color == null) {
        return '#1867c0'
      }
      return this.event.color
    },
    eventTime() {
      if (this.event == null || this.event.date == null) {
        return 'Date is null'
      }
      return this.$moment(this.event.date).format('YYYY-MM-DD HH:mm')
    },
    eventSubmission(){
      if(this.event == null || isEmptyList(this.event.users) || this.event.userId === this.myId){
        return null;
      }
      if(this.event.status === CalendarStatusEnum.CANCELLED){
        return null;
      }
      let me = this.event.users.find(u => u.userId === this.myId);
      if(me == null){
        return null;
      }
      return me.status || CalendarStatusEnum.PENDING;
    }
  },
  methods: {
    ...mapActions('calendar', ['getCalendarById', 'updateUserStatus']),
    ...mapActions('clients', ['getAllClients']),
    load() {
      let $this = this
      $this.loading.event = true
      $this.loading.clients = true
      console.log('load calendar: ' + event.title)
      this.get({ id: $this.event.id })
          .then((event) => {
            if (event == null) {
              return $this.error = 'Load event failed'
            }
            $this.reloadClients();
            $this.loading.event = false
          }, (error) => {
            $this.error = error
          })
    },
    reloadClients(){
      let $this = this;
      $this.loading.clients = true
      if (this.event.clientsIds == null || this.event.clientsIds.length <= 0) {
        this.clients = [];
        $this.loading.clients = false;
        return
      }
      this.getAllClients({ filter: { ids: this.event.clientsIds } })
          .then(
              (clientData) => $this.clients = clientData.data.map(client => {
                let fn = client.firstName != null ? client.firstName.trim() : ''
                let ln = client.lastName != null ? client.lastName.trim() : ''
                let acronym = (fn !== '' ? fn.charAt(0)
                    .toUpperCase() : '') + (ln !== '' ? ln.charAt(0)
                    .toUpperCase() : '')
                return { id: client.id, name: fn + ' ' + ln, acronym }
              })
          ).finally(() => $this.loading.clients = false)
    },
    onClientSelect(id) {
      console.log('CalendarEvent -> onClientSelect', id)
      this.showClient(id)
    },
    badgeVariant(userData){
      if(userData == null || userData.status == null || userData.status === CalendarStatusEnum.PENDING){
        return 'dark';
      }
      if(userData.status === CalendarStatusEnum.APPROVED){
        return 'success';
      }
      if(userData.status === CalendarStatusEnum.REJECTED){
        return 'danger';
      }
      return 'dark';
    },
    responseEvent(status){
      let $this = this;
      this.loading.event = true;
      this.updateUserStatus({eventId: this.event.id, userId:this.myId, status: status})
          .then(calendarEvent => $this.$emit('updated', calendarEvent)).finally(() => $this.loading.event = false)
    }
  }
}
</script>

<style scoped>

p {
  margin: 0;
}

.event-details .event-details-title {
  text-transform: capitalize;
  line-height: 28px;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #3c4043;
}

.event-details .event-details-description {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3c4043;
}

.event-details .capitalize {
  text-transform: capitalize;

}

.event-details .event-details-description ul {
  margin: 0;
  padding: 0;
}

.event-details .event-details-description ul > li {
  list-style: none;
}
</style>
<style>
.event-details .ps .ps__rail-x,
.event-details .ps .ps__rail-y {
  opacity: 1;
}

.event-details .ps__rail-y > .ps__thumb-y {
  width: 11px;
}

.event-details .ps .ps__rail-y {
  background-color: #eee;
  opacity: 0.9;
}
</style>
