<template>
  <div>
    <!-- Body -->
    <div v-if="loading !== true">
      <!-- Canceled alert -->
      <div>
        <b-alert class="mx-1 p-1" v-if="calendarEvent.status === 'CANCELLED'" variant="danger" show>
          <i class="fa-solid fa-triangle-exclamation"></i>
          {{$t('calendar.canceledEvent')}}
        </b-alert>
        <b-alert class="mx-1 p-1" v-else-if="!editable" variant="warning" show>
          <i class="fa-solid fa-triangle-exclamation"></i>
          {{$t('calendar.readOnlyMsg')}}
        </b-alert>
      </div>
      <!-- Form -->
      <validation-observer #default="{ invalid }" ref="refFormObserver">
        <!-- Form -->
        <div>
          <!-- Title -->
          <validation-provider #default="validationContext" :name="$t('calendar.title')" rules="required">
            <b-form-group :label="$t('calendar.title')" label-for="event-title">
              <b-form-input
                  id="event-title"
                  v-model="calendarEvent.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Textarea -->
          <b-form-group :label="$t('calendar.description')" label-for="event-description">
            <b-form-textarea
                id="event-description"
                v-model="calendarEvent.body"
            />
          </b-form-group>
          <b-form-group :label="$t('calendar.date')" label-for="event-description">
            <calendar-picker-range :need-footer="false" mode="dateTime" v-bind:value="eventRange" @input="setEventRange"/>
          </b-form-group>

          <!-- Calendar type-->
          <validation-provider #default="validationContext" name="Calendar" rules="required">
            <b-form-group :label="$t('calendar.type')" label-for="calendar"
                          :state="getValidationState(validationContext)"
            >
              <v-select v-model="calendarEvent.type" :options="types"
                        label="label" :reduce="type => type.name" :clearable="false"
              >
                <template #option="{ color, label }">
                  <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ color, label }">
                  <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Event URL -->
          <validation-provider #default="validationContext" name="Event URL" rules="url">
            <b-form-group
                :label="$t('calendar.url')"
                label-for="event-url"
            >
              <b-form-input
                  id="event-url"
                  v-model="calendarEvent.url"
                  type="url"
                  :state="getValidationState(validationContext)"
                  trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Users -->
          <b-form-group :label="$t('calendar.representatives')" label-for="add-guests">
            <user-picker v-model="calendarEvent.users" multiple exclude-me/>
          </b-form-group>

          <!-- Org and Client -->
          <b-row>
            <b-col cols="2" v-if="!editing && hasManyOrganizations">
              <b-form-group :label="$t('columns.org')" label-for="add-guests">
                <organization-select-box acronym v-model="calendarEvent.orgId" :clearable="false"/>
              </b-form-group>
            </b-col>
            <b-col v-bind:cols="!editing && hasManyOrganizations ? 10 : 12">
              <b-form-group
                  :label="$t('calendar.clients')"
                  label-for="add-guests">

                <client-picker v-bind:org="calendarEvent.orgId" v-model="calendarEvent.clientsIds"
                               :disabled="calendarEvent.orgId == null || calendarEvent.orgId === ''"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Location -->
          <b-form-group :label="$t('calendar.location')" label-for="event-location">
            <b-form-input id="event-location" v-model="calendarEvent.location" trim/>
          </b-form-group>
        </div>
      </validation-observer>
      <!-- Form Actions -->
      <div class="mt-2 " v-if="editable">
        <b-button variant="primary" class="mr-2" block type="submit" :disabled="invalid" v-if="editable" @click="save">
          {{ editing ? $t('operations.update') : $t('operations.add') }}
        </b-button>
      </div>
    </div>
    <!-- skeleton -->
    <div class="p-1" v-else>
      <label>{{$t('calendar.title')}}</label>
      <b-skeleton type="input"></b-skeleton>
      <label>{{$t('calendar.description')}}</label>
      <b-skeleton-img no-aspect height="50px"></b-skeleton-img>
      <b-row>
        <b-col cols="6">
          <label>{{$t('calendar.startDate')}}</label>
          <b-skeleton type="input"></b-skeleton>
        </b-col>
        <b-col cols="6">
          <label>{{$t('calendar.endDate')}}</label>
          <b-skeleton type="input"></b-skeleton>
        </b-col>
      </b-row>
      <label>{{$t('calendar.url')}}</label>
      <b-skeleton type="input"></b-skeleton>
      <label>{{$t('calendar.representatives')}}</label>
      <b-skeleton type="input"></b-skeleton>
      <label>{{$t('calendar.clients')}}</label>
      <b-skeleton type="input"></b-skeleton>
      <label>{{$t('calendar.location')}}</label>
      <b-skeleton type="input"></b-skeleton>
    </div>

  </div>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'
import mixinValidation from '@/components/mixin/mixinValidation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CalendarPickerRange from '@/components/widget/picker/calendarPickerRange'
import CalendarRequest from '@/model/calendar/CalendarRequest'
import ClientPicker from '@/components/widget/picker/clientPicker'
import { mapActions, mapGetters } from 'vuex'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox'
import UserPicker from '@/components/widget/picker/userPicker'

export default {
  name: 'CalendarEvent',
  components: {
    UserPicker,
    OrganizationSelectBox,
    ClientPicker,
    CalendarPickerRange,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [mixinBase, mixinNotifications, mixinPermissions, mixinValidation],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.refresh()
      },
      immediate: true
    }
  },
  data: () => ({
    calendarEvent: CalendarRequest(),
    otherUsers: [],
  }),
  computed:{
    ...mapGetters('user', ['myId']),
    editable(){
      if(this.calendarEvent.status === 'CANCELLED'){
        return false
      }
      return !this.editing || this.calendarEvent.userId === this.myId || this.hasPermission(this.permissions.CALENDAR_UPDATE_ALL)
    },
    editing(){
      return this.calendarEvent.id != null && this.calendarEvent.id !== ''
    },
    eventRange(){
      return {
        start: this.calendarEvent.start,
        end: this.calendarEvent.end
      }
    },
    types(){
      let types = this.$t('calendarTypes');
      return Object.keys(types).map((key) => {
        return {
          name: key,
          label: this.$t(`calendarTypes.${key}.name`),
          color: this.$t(`calendarTypes.${key}.color`)
        }
      });
    },
  },
  methods:{
    ...mapActions('calendar', ['addCalendar', 'updateCalendar']),
    refresh(){
      let $this = this;
      this.calendarEvent = {
        ...this.value, users: ($this.value.users || []).map((u) => u.userId)
      }
    },
    setEventRange(value){
      this.calendarEvent.start = value.start
      this.calendarEvent.end = value.end
    },
    save(){
      let $this = this;
      let promise = this.calendarEvent.id != null ?
          this.updateCalendar({ calendar: this.calendarEvent, id: this.calendarEvent.id }) :
          this.addCalendar(this.calendarEvent);
      promise.then((calendar) => {
        console.log('calendar', calendar)
        $this.$emit('input', calendar)
        $this.$emit('submitted', calendar)
        $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.calendar') }));
      }, err => {
        $this.showErrorNotification($this.$t('globalMessages.updateDataError', { system: $this.$t('menu.calendar') }), $this.getErrorDescription(err));
      })
    }
  }
}
</script>

<style scoped>

</style>
