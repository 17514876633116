<template>
<div>
  <b-card>
    <b-row>
      <b-col cols="4" lg="2">
        <div>
          <b-button block variant="primary" @click="addEvent">{{$t('calendar.action.addEvent')}}</b-button>
<!--          Types-->
          <h5 class="section-label mb-1 mt-2">{{$t('calendar.types')}}</h5>
          <div>
            <b-form-checkbox-group v-model="selectedTypes"  name="calendar-types" stacked>
              <b-form-checkbox v-for="type in types" :value="type">
                <b-alert class="pr-1" :variant="$t(`calendarTypes.${type}.color`)" show style="min-width: 130px">
                  {{$t(`calendarTypes.${type}.name`)}}
                </b-alert>
              </b-form-checkbox>
              <b-form-checkbox class="mb-1" value="approved" >
                <i class="fa-solid fa-circle text-success" style="font-size: 12px;margin-right: 5px"></i>
                <span>{{$t('calendar.approved')}}</span>
              </b-form-checkbox>
              <b-form-checkbox class="mb-1" value="pending" >
                <i class="fa-solid fa-circle text-dark" style="font-size: 12px;margin-right: 5px"></i>
                <span>{{$t('calendar.pending')}}</span>
              </b-form-checkbox>
              <b-form-checkbox class="mb-1" value="rejected" >
                <i class="fa-solid fa-circle text-danger" style="font-size: 12px;margin-right: 5px"></i>
                <span>{{$t('calendar.rejected')}}</span>
              </b-form-checkbox>
              <b-form-checkbox class="mb-1" value="cancelled" >
                <span class="pr-1" style="text-decoration-line: line-through">{{$t('calendar.cancelled')}}</span>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
<!--          &lt;!&ndash; Calendar &ndash;&gt;
          <div v-if="hasPermission(permissions.CALENDAR_READ_ALL)">
            <h5 class="section-label mb-1 mt-2">{{$t('menu.calendar')}}</h5>
            <b-input size="sm" v-if="allUsers.length > 10" v-model="searchUser" :debounce="500" style="margin-bottom: 10px" :placeholder="$t('calendar.action.searchUser')"></b-input>
            <perfect-scrollbar  :options="{suppressScrollX:true}" class="position-relative" style="max-height: 200px">
              <b-form-checkbox-group v-model="selectedUsers"  name="calendars" stacked>
                <b-form-checkbox style="white-space: nowrap" v-for="user in allUsersFiltered" :value="user.id" >
                  <user-avatar :user-id="user.id" :size="20"/>
                  <span style="margin-left: 3px">{{user.firstName}} {{user.lastName}}</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </perfect-scrollbar>
          </div>-->


        </div>

      </b-col>
      <b-col cols="8" lg="10">
        <calendar v-bind:types="selectedTypes" ref="calendar"/>
      </b-col>
    </b-row>
  </b-card>

</div>
</template>

<script>
import Calendar from '@/components/partial/calendar/Calendar'
import { mapGetters } from 'vuex'
import UserAvatar from '@/components/widget/UserAvatar'
import mixinBase from '@/components/mixin/mixinBase'
import mixinPermissions from '@/components/mixin/mixinPermissions'
export default {
  name: 'CalendarView',
  components: { UserAvatar, Calendar },
  mixins: [mixinBase, mixinPermissions],
  data: () => ({
    selectedTypes: [],
    selectedUsers: [],
    searchUser: '',
  }),
  computed:{
    ...mapGetters('user', ['myId', 'user']),
    ...mapGetters('data', ['allUsers', 'getUserById']),
    types(){
      let types = this.$t('calendarTypes');
      return Object.keys(types);
    },
    allUsersFiltered(){
      let $this = this;
      let users =  [$this.user, ...$this.allUsers.filter(u=>u.id !== $this.myId)];
      if(this.searchUser != null && this.searchUser !== ''){
        let names = this.searchUser.toLowerCase().split(' ');
        users = users.filter(u=>$this.selectedUsers.includes(u.id) || names.some(n=>u.firstName.toLowerCase().includes(n.toLowerCase()) || u.lastName.toLowerCase().includes(n.toLowerCase())));
      }
      users.sort((a,b)=>{
        if($this.selectedUsers.includes(a.id) && !$this.selectedUsers.includes(b.id)) return -1;
        if(!$this.selectedUsers.includes(a.id) && $this.selectedUsers.includes(b.id)) return 1;
        return a.firstName.localeCompare(b.firstName);
      });
      return users;
    }
  },
  created() {
    // this.selectedTypes = [...this.types, 'cancelled', 'rejected', 'approved', 'pending'];
    this.selectedTypes = [...this.types, 'approved', 'pending'];
    this.selectedUsers = [this.myId];
  },
  methods:{
    addEvent() {
      this.$refs['calendar'].onDateClick({ dateStr:this.$moment().format('YYYY-MM-DD HH:mm') });
    }
  }

}
</script>

<style scoped>

</style>
