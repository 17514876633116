import { logUnusedKeys } from '@/model/Utils'

export default function (other) {
  other = other || {}
  let result = {
    id: other.id || null,
    userId: other.userId || null,
    creationTime: other.creationTime || null,
    status: other.status || null,
    start: other.start || null,
    end: other.end || null,
    lastStart: other.lastStart || null,
    lastEnd: other.lastEnd || null,
    type: other.type || null,
    title: other.title || null,
    duration: other.duration || null,
    body: other.body || null,
    location: other.location || null,
    url: other.url || null,
    color: other.color || null,
    clientsIds: other.clientsIds || [],
    users: other.users || [],
  }
  logUnusedKeys('Calendar', result, other)
  return result
}
