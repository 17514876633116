<template>
  <b-modal no-fade v-model="dialog.show" size="lg" :ref="`modal-calendar-${uniqueId}`"
           :id="`modal-calendar-${uniqueId}`"
           :hide-footer="true" centered @show="closeAllPopovers" @close="onDialogClose"
           no-close-on-backdrop
  >
    <template #modal-title>
      {{isNewEvent ? '' : calendarEvent.title}}
    </template>
    <calendar-event v-bind:event-id="id" v-model="calendarEvent" v-bind:loading="loading" @submitted="$emit('submitted')"/>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'
import mixinPopover from '@/components/mixin/mixinPopover'
import Calendar from '@/model/calendar/Calendar'
import { mapActions, mapGetters } from 'vuex'
import CalendarRequest from '@/model/calendar/CalendarRequest'
import CalendarEvent from '@/components/partial/calendar/CalendarEvent'

export default {
  name: 'calendarEventModal',
  components: { CalendarEvent },
  mixins: [mixinBase, mixinPopover],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewEvent: false,
    calendarEvent: Calendar(),
  }),
  created() {
    this.uniqueId = this._uid
    if (this.queryParams.eventId != null) {
      this.showModal(this.queryParams.eventId)
    }
  },
  computed:{
    ...mapGetters('data', ['anyOrganization']),
  },
  methods: {
    ...mapActions('calendar', ['getCalendarById']),
    showModal(id, time) {
      this.id = id
      this.loading = true
      this.dialog.show = true
      this.dialog.tab = 0
      this.isNewEvent = this.id == null || this.id === 0
      if (!this.isNewEvent) {
        this.refreshData()
        this.pushState({ eventId: id })
      } else {
        this.calendarEvent = CalendarRequest({ start: time, end: time, type:"EVENT", status:"APPROVED", orgId: this.anyOrganization.id })
        this.error = null
        this.loading = false
      }
    },
    closeModal() {
      this.dialog.show = false
      this.calendarEvent = {}
      this.onDialogClose()
    },
    onDialogClose() {
      this.id = null
      this.pushState({ eventId: null, })
    },
    refreshData() {
      let $this = this
      this.getCalendarById(this.id)
          .then(calendarEvent => {
            $this.calendarEvent = calendarEvent
            $this.error = null
            $this.loading = false
          }, err => {
            $this.error = $this.getErrorDescription(err)
            $this.loading = false
          })
    },
    onSubmitted(server) {
      if (server.id != null) {
        this.selected = server.id
      }
      this.refreshData()
      this.dialog.tab = 0
    }
  }
}
</script>

<style scoped>

</style>
