<template>
  <div>
    <v-date-picker class="inline-block h-full" :masks="masks" v-bind="$props" v-model="valueDate" is-range ref="picker" v-bind:key="refreshKey" is24hr>
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="date-picker-range-container d-flex align-items-center" :class="{'clearable':clearable}" style="width: 100%">
          <!-- Start Date -->
          <div class="d-inline-block" style="width: 100%">
            <div class="flex items-center">
              <b-input-group :size="size" v-on="inputEvents.start" >
                <template #prepend>
                  <b-input-group-text><i class="far fa-calendar-alt"></i></b-input-group-text>
                </template>
                <b-form-input class="text-center" type="text" v-bind:value="formattedTime.start" :placeholder="$t('filters.start')" readonly />
                <template #append v-if="clearable">
                  <b-input-group-text @click="clearInput"><i class="fa-solid fa-xmark"></i></b-input-group-text>
                </template>
              </b-input-group>
            </div>
          </div>
          <div class="date-picker-arrow"><i class="fa-solid fa-arrow-right"></i></div>
          <!-- End Date -->
          <div class="d-inline-block" style="width: 100%">
            <div class="flex items-center">
              <b-input-group :size="size" v-on="inputEvents.end">
                <template #prepend>
                  <b-input-group-text><i class="far fa-calendar-alt"></i></b-input-group-text>
                </template>
                <b-form-input class="text-center" type="text" v-bind:value="formattedTime.end" :placeholder="$t('filters.end')" readonly/>
                <template #append v-if="clearable">
                  <b-input-group-text @click="clearInput"><i class="fa-solid fa-xmark"></i></b-input-group-text>
                </template>
              </b-input-group>
            </div>
          </div>
        </div>
      </template>
      <template v-if="needFooter" v-slot:footer>
        <div class="bg-gray-100 text-center p-1 border-t rounded-b-lg" v-if="mode !== 'time'">
          <b-button variant="outline-primary" size="sm" @click="setThisMonth">{{$t('filters.picker.thisMonth')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click="setLastMonth">{{$t('filters.picker.lastMonth')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click="setDays(30)">{{$t('filters.picker.last30Days')}}</b-button>
          <b-button variant="outline-primary" size="sm" @click="setThisYear">{{$t('filters.picker.thisYear')}}</b-button>
          <b-button block variant="outline-primary" size="sm" @click="setToToday">{{$t('filters.picker.today')}}</b-button>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'

export default {
  name: 'calendarPickerRange',
  mixins: [mixinBase],
  data: () => ({
    currentValue: {
      start: null,
      end: null
    },
    changes:0,
    masks: {
      L: 'yyyy-dd-MM',
      input: ['yyyy-dd-MM'],
      inputDateTime: ['yyyy-dd-MM HH:mm'],

      // ...optional `title`, `weekdays`, `navMonths`, etc
    }
  }),
  watch:{
    value:{
      handler(val){
        let range = val ? {start: val.start, end: val.end} : {start: null, end: null};
        let hasChanged = false;
        if(val.start != null && this.$moment(range.start, this.i18nInputKey, true).isValid()){
          range.start = this.$moment(range.start, this.i18nInputKey).format(this.i18nInputKey);
          hasChanged = range.start !== this.valueDate.start;
          //console.log("picker start has change " + hasChanged, {currentVal: this.valueDate, newVal: val, i18nInputKey: this.i18nInputKey})
        }
        if(val.end != null && this.$moment(range.end, this.i18nInputKey, true).isValid()){
          range.end = this.$moment(range.end, this.i18nInputKey).format(this.i18nInputKey);
          hasChanged = range.end !== this.valueDate.end;
          //console.log("picker end has change " + hasChanged, {currentVal: this.valueDate, newVal: val, i18nInputKey: this.i18nInputKey})
        }
        if(this.changes > 10){
          console.warn("Changes reached max - preventing infinite loop")
          return
        }
        if(hasChanged){
          this.changes++;
          this.$emit('input', range);
        }
      },
      deep:true
    }
  },
  props: {
    value: {
      required: true,
      default: () => null
    },
    mode: {
      required: false,
      default: () => 'date'
    },
    size: {
      required: false,
      default: () => 'sm'
    },
    needFooter: {
      required: false,
      default: () => true
    },
    minDate: {},
    maxDate: {},
    disabledDates: {},
    availableDates: {},
    clearable:{
      required: false,
      default: () => true
    }
  },
  computed: {
    valueDate: {
      get() {
        return this.currentValue;
      },
      set(val) {
        if (val.start != null) {
          val.start = this.$moment(val.start)
              .format(this.i18nInputKey)
        }
        if (val.end != null) {
          val.end = this.$moment(val.end)
              .format(this.i18nInputKey)
        }
        this.currentValue = {...val};
        this.$emit('input', val)
      }
    },
    formattedTime() {
      // console.log('formattedTime', this.valueDate)
      let result = {
        start: '',
        end: ''
      }
      let startTime = this.$moment(this.valueDate.start, this.i18nInputKey)
      let endTime = this.$moment(this.valueDate.end, this.i18nInputKey)
      if (startTime.isValid()) {
        result.start = startTime.format(this.i18nFormatKey)
      }
      if (endTime.isValid()) {
        result.end = endTime.format(this.i18nFormatKey)
      }
      return result
    },
    i18nMaskKey() {
      return this.$t('timeFormat.' + this.mode + '.mask')
    },
    i18nFormatKey() {
      return this.$t('timeFormat.' + this.mode + '.format')
    },
    i18nInputKey() {
      return this.mode === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'
    },
  },
  methods: {
    setInput(val) {
      this.$emit('input', val)
    },
    clearInput() {
      this.$emit('input', null);
      this.forceUpdate();
    },
    setToToday() {
      let valueDate = {
        start: this.$moment().format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.forceUpdateValue(valueDate);
      this.moveCalendar(valueDate);
    },
    setThisMonth(){
      let valueDate = {
        start: this.$moment().startOf('month').format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.forceUpdateValue(valueDate);
      this.moveCalendar(valueDate);
    },
    setLastMonth(){
      let valueDate = {
        start: this.$moment().subtract(1, 'month').startOf('month').format(this.i18nInputKey),
        end: this.$moment().subtract(1, 'month').endOf('month').format(this.i18nInputKey)
      }

      this.valueDate = valueDate;
      this.moveCalendar(valueDate);
      this.forceUpdateValue(valueDate);
    },
    setDays(days){
      let valueDate = {
        start: this.$moment().subtract(days, 'days').format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.moveCalendar(valueDate);
      this.forceUpdateValue(valueDate);
    },
    setThisYear(){
      let valueDate = {
        start: this.$moment().startOf('year').format(this.i18nInputKey),
        end: this.$moment().format(this.i18nInputKey)
      }
      this.valueDate = valueDate;
      this.moveCalendar(valueDate);
      this.forceUpdateValue(valueDate);
    },
    forceUpdateValue(valueDate){
      this.$refs.picker.forceUpdateValue(valueDate);
    },
    moveCalendar(valueDate){
      let moment = this.$moment(valueDate.start,this.i18nInputKey);
      let month = parseInt(moment.format("M"));
      let year = parseInt(moment.format("YYYY"));
      this.$refs.picker.move({ month, year });
    }
  }
}
</script>

<style lang="scss">
.date-picker-range-container {
  .date-picker-arrow {
    margin-right: 5px;
    margin-left: 5px;
  }
  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-right: none !important;
        padding-right: 1px;
      }
    }
    input.form-control {
      border-left: none !important;
    }
  }
  &.clearable {
    .input-group-append{
      .input-group-text {
        padding-left: 1px;
        padding-right: 6px;
        cursor: pointer;
      }
    }
    input.form-control {
      border-left: none !important;
      border-right: none !important;
      padding: 0;
    }
  }
}
</style>
