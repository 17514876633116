<template>
  <div>
    <div class="calendar">
      <div  style="position: absolute; margin-top: 35px; right: 25px">
        <b-spinner small v-if="loading">{{$t('operations.loading')}}</b-spinner>
        <b-link v-else><i class="fa-solid fa-rotate-right" @click="refreshCalendar"></i></b-link>
      </div>

      <FullCalendar  ref="calendar" v-bind:options="calendarOptions"/>
      <template v-if="dialogs.popovers">
        <b-popover v-for="(event, index) in events" v-bind:key="event.id" :ref="'popover_cal_'+event.id" :target="'ev-'+event.id"
                   @show=" $root.$emit('bv::hide::popover');"
                   triggers="click focus" size="xl" placement="right"
                   style="max-width: none">
          <template slot="title">
            <b-row>
              <b-col cols="9">
                {{ event.title }}
              </b-col>
              <b-col cols="3">
                <div class="d-flex align-items-center justify-content-end" >
                  <b-link style="color: inherit;" @click="dismissEvent(index, event.id)" v-b-tooltip :title="$t('operations.dismiss')" v-if="event.userId === myId">
                    <feather-icon icon="ArchiveIcon"/>
                  </b-link>
                  <span class="mr-1"></span>
                  <b-link style="color: inherit;" @click="editEvent(event.id)" :title="$t('operations.edit')">
                    <feather-icon icon="Edit3Icon"/>
                  </b-link>
                  <span class="mr-1"></span>
                  <b-link style="color: inherit;" @click="closePopover" :title="$t('operations.close')">
                    <feather-icon icon="XIcon"/>
                  </b-link>
                </div>
              </b-col>
            </b-row>

          </template>
          <div :temp="'ev-'+event.id">
            <div class="event-popover-content">
              <calendar-event-basic v-bind:event="events[index]" @clientSelect="onClientSelect" @updated="ev=>updateEvent(index,ev)"/>
            </div>
          </div>
        </b-popover>
      </template>
    </div>
    <calendar-event-modal ref="eventModal" @submitted="refreshCalendar"/>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list'
import { mapActions, mapGetters } from 'vuex'
import calendarEventBasic from '@/components/partial/calendar/calendarEventBasic'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import mixinTable from '@/components/mixin/mixinTable'
import CalendarEventModal from '@/components/modal/calendarEventModal'
import { CalendarStatusEnum } from '@/model/calendar/CalendarStatusEnum'
import mixinPopover from '@/components/mixin/mixinPopover'
export default {
  name: 'Calendar',
  mixins: [mixinBase, mixinPopover, mixinNotifications, mixinTable],
  components: {
    CalendarEventModal,
    FullCalendar,
    calendarEventBasic
  },
  data() {
    return {
      calendarOptions: {
        fixedWeekCount: false,
        // height: 800,
        locales: allLocales,
        locale: this.$t('lang.base') || 'en',//this.currentLang, // the initial locale
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          // start: 'sidebarToggle, prev,next, title',
          start: 'prev,next,title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        initialView: 'dayGridMonth',
        //dateClick: this.onDateClick,
        editable: true,
        eventDrop: this.moveEvent,
        events: this.onCalendarRefresh,
        eventClick: this.onEventClick,
        eventDidMount: this.onEventDidMount,
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false
        }

      },

      events: [],
      cal: { start: '', end: '' },
      loadedExternalEvents: {},
      dialogs: {
        popovers: false
      },
      listeners:{
        refresh(){}
      }
    }
  },
  props:{
    types:Array,
    users:Array,
  },
  watch: {
    types:{
      handler() {
        this.refreshCalendar()
      },
      // immediate: true
    },
    users:{
      handler() {
        this.refreshCalendar()
      },
      // immediate: true
    }
  },
  created() {
    console.log('calendar created')
    this.calendarOptions.locale = 'en'
    this.$root.$on('crm::calendar::refresh',this.refreshCalendar)
  },
  beforeDestroy() {
    this.$root.$off('crm::calendar::refresh',this.refreshCalendar)
  },
  computed: {
    ...mapGetters('user', ['myId']),
  },
  methods: {
    ...mapActions('calendar', ['getExternalCalendars', 'getCalendars', 'cancelEvent']),
    refreshCalendar() {
      console.log('refreshCalendar')
      let calendarApi = this.$refs.calendar.getApi()
      calendarApi.refetchEvents()
    },
    onCalendarRefresh(info, successCallback, failureCallback) {
      console.log('onCalendarRefresh')
      this.cal.start = this.$moment(info.start).startOf('month')
          .format('YYYY-MM-DD')
      this.cal.end = this.$moment(info.end).endOf('month')
          .format('YYYY-MM-DD')
      this.refresh()
          .then(() => successCallback(this.adaptEvents()), failureCallback)
    },
    refresh() {
      let $this = this
      let token = this.setLoading();
      return new Promise((resolve, reject) => {
        this.getCalendars({
          from: $this.cal.start,
          until: $this.cal.end,
          userid:-1,
          repList: this.users
        })
            .then(
                (events) => {
                  $this.updateEvents(events)
                  resolve()
                },
                reject
            )
      }).finally(() => {
        this.setLoaded(token)
      })
    },
    updateEvents(apiEvents) {
      if (!Array.isArray(apiEvents)) {
        apiEvents = []
      }
      this.events=apiEvents.map(e=>({...e, external : false}));
      return this.events
    },
    adaptEvents() {

      let events =  this.events.filter(this.eventsFilter).map(ev => ({
        start: ev.start,
        end: ev.end,
        title: (ev.status === CalendarStatusEnum.CANCELLED ? `[${this.$t('calendar.cancelled')}] ` : '') + this.eventTitleForPreview(ev),
        id: 'ev-' + ev.id,
        rawId: ev.id,
        classNames:[('bg-light-'+this.$t(`calendarTypes.${ev.type}.color`)), `fc-dot-${this.badgeVariant(ev)}`, (ev.status === CalendarStatusEnum.CANCELLED ? 'fc-event-cancelled' : '')],
        draggable: (ev.userid === this.myId),
        editable: (ev.userid === this.myId),
        overlap: (ev.userid === this.myId),
      }))
      console.log("adaptEvents", events);
      return events;
    },
    badgeVariant(event){
      if(event == null || event.userId === this.myId){
        return 'primary';
      }
      let userData = event.users.find(u=>u.userId === this.myId)
      if(userData == null || userData.status == null || userData.status === CalendarStatusEnum.PENDING){
        return 'dark';
      }
      if(userData.status === CalendarStatusEnum.APPROVED){
        return 'success';
      }
      if(userData.status === CalendarStatusEnum.REJECTED){
        return 'danger';
      }
      return 'dark';
    },
    eventTitleForPreview(event){
      if (event.clientsIds==null || event.clientsIds.length === 0 || event.clientNames == null) {
        return event.title;
      }

      let clientId = event.clientsIds[0];
      return event.clientNames[clientId];

    },
    eventsFilter(ev) {
      if(!Array.isArray(this.types)){
        return true;
      }
      if(!this.types.includes('cancelled') && ev.status === CalendarStatusEnum.CANCELLED){
        return false;
      }
      if(!this.types.includes('rejected') && this.badgeVariant(ev) === 'danger'){
        return false;
      }
      if(!this.types.includes('approved') && this.badgeVariant(ev) === 'success'){
        return false;
      }
      if(!this.types.includes('pending') && this.badgeVariant(ev) === 'dark'){
        return false;
      }
      return this.types.includes(ev.type);
    },
    onEventDidMount: function (eventInfo) {
      let $this = this;
      eventInfo.el.id = eventInfo.event.id

      eventInfo.el.ondblclick = function (){
        $this.editEvent(eventInfo.event.extendedProps.rawId)
      }
      this.refreshPopovers()
    },
    refreshPopovers() {
      let $this = this
      //Hack in order to reload the popovers
      this.dialogs.popovers = false
      setTimeout(() => {
        this.dialogs.popovers = true
        $this.$forceUpdate()
      }, 1000)
    },

    onDateClick(arg) {
      this.$refs['eventModal'].showModal(null, arg.dateStr);
    },
    moveEvent(info) {
      let eventInfo = info.event
      if (!confirm('Are you sure about this change?')) {
        info.revert()
        return
      }
      let event = this.events.find(e => e.id === parseInt(eventInfo.id.substr(3)))
      if (event == null) {
        info.revert()
        return
      }
      event = { ...event }
      event.start = this.$moment(eventInfo.start)
          .format('YYYY-MM-DD HH:mm')
      event.end = this.$moment(eventInfo.end)
          .format('YYYY-MM-DD HH:mm')
      this.update({
        id: event.id,
        event: event
      })
      this.refreshPopovers()
    },
    editEvent(id) {
      this.$refs['eventModal'].showModal(id);
      this.closePopover()
    },
    onEventClick(eventInfo) {
      this.$root.$emit('bv::show::popover', 'ev' + eventInfo.event.id)
    },
    closePopover(popover) {
      if (popover != null && typeof popover === 'string') {
        this.$root.$emit('bv::hide::popover', popover)
      } else {
        this.$root.$emit('bv::hide::popover')
      }
    },
    onClientSelect(id) {
      console.log('calendar-> onClientSelect(' + id + ')')
      this.$root.$emit('crm::client::select', id)
    },
    async dismissEvent(index, id) {
      this.closeAllPopovers()
      let result = await this.$bvModal.msgBoxConfirm(this.$t('calendar.action.dismissMsg', {event:this.events[index].title}), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        hideHeaderClose: false,
        centered: true
      });
      if(!result){
        return
      }
      let $this = this
      this.cancelEvent(id)
          .then(
              ev => {
                $this.updateEvent(index, ev)
              },
              err => $this.showErrorNotification($this.$t('globalMessages.updateDataError', { system: $this.$t('menu.clients') }), err.error)
          )
    },
    updateEvent(index, ev) {
      let event = this.events[index];
      if(event.id === ev.id){
        this.events[index] = ev;
        this.refreshPopovers();
        this.refreshCalendar();
      }
    },
  }
}
</script>

<style lang="scss">

.dark-layout{
  .fc .fc-day-today {
    background-color: rgba(27, 35, 55, 0.82) !important;
    color: #ffffff !important;
  }

  .fc .fc-list .fc-list-day-cushion {
    background-color: rgba(27, 35, 55, 0.82) !important;
  }
}
.calendar{


  .fc-daygrid-event {
    &.bg-light-info .fc-event-main{
      color: #1b2337 !important;
    }
    &.fc-dot-success .fc-daygrid-event-dot {
      border-color: #28a745;
    }

    &.fc-dot-danger .fc-daygrid-event-dot {
      border-color: #dc3545;
    }

    &.fc-dot-dark .fc-daygrid-event-dot {
      border-color: #4b4b4b;
    }

    &.fc-dot-primary .fc-daygrid-event-dot {
      border-color: #007bff;
    }

    &.fc-event-cancelled {
      opacity: 0.8;
      text-decoration-line: line-through !important;
      .fc-event-title{
        text-decoration-line: line-through !important;
      }
    }

  }
}
</style>
