<template>
  <v-select ref="client-select" v-bind:multiple="multi || selectProps.multiple"
            v-model="selected" :disabled="disabled"
            :filterable="false" :options="options" @search="onSearch"
  >
    <template #option="{label}">
      <b-avatar button  class="pull-up" size="sm" variant="primary" :text="clients[label].acronym" v-b-tooltip :title="clients[label].name"></b-avatar>
      {{ clients[label].name }} ({{ clients[label].buName}})
    </template>
    <template #selected-option="{label}">
      <div>
        <div v-if="clients[label]">
          <b-avatar v-if="disabled == null || disabled === false" button  class="pull-up" size="sm" variant="primary" :text="clients[label].acronym" v-b-tooltip :title="clients[label].name"></b-avatar>
          {{ clients[label].name }} ({{ clients[label].buName}})
        </div>
        <div v-else>
          <b-skeleton size="sm" type="avatar"/>
        </div>
      </div>
    </template>
    <template #list-header>
      <div class="text-right" v-if="optionsPages > 1 && loading !== true">
        <b-button variant="outline-primary" size="sm" @click="updatePage(-1)" v-if=" optionsPage > 1" :disabled="loading">Prev</b-button>
        <span>({{optionsPage}}/{{optionsPages}})</span>
        <b-button variant="outline-primary" size="sm"  @click="updatePage(1)" v-if="optionsPage < optionsPages"  v-bind:disabled="loading">Next</b-button>
      </div>
      <div class="text-right" v-if="optionsPages > 1 && loading !== false">
        <b-spinner small></b-spinner>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Debounce } from '@/model/Utils'

export default {
  name: 'clientPicker',
  props: {
    value: {
      required: true,
    },
    multi: {
      required: false,
    },
    selectProps: {
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    org:{},
    clearable: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    paginationSize: {
      type: Number,
      default: () => 10
    },
  },
  data: () => ({
    clients: {},
    options: [],
    optionsPages: 0,
    optionsPage: 1,
    searchInput: '',
    loading: false,
    error: '',
    refreshOptionsDebounce: Debounce(() => {
    }, 500),
  }),
  watch:{
    value: {
      handler: function (val) {
        this.refreshAllClients()
      },
      immediate: true
    },
    org: {
      handler: function (val) {
        this.validateClientsOrg()
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('data', ['getOrganizationById', 'getBusinessUnitById']),
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  created() {
    this.refreshOptionsDebounce = Debounce(this.refreshOptions, 500)
    this.refreshOptions(null);
  },
  methods: {
    ...mapActions('clients', ['getAllClients']),
    refreshAllClients(){
      let selected = (this.selected || []).filter(id => this.clients[id] == null);
      let $this = this
      if(selected.length <= 0){
        return;
      }
      this.loading = true
      this.getAllClients({ filter: { ids: selected } })
          .then(clients => {
            this.options = clients.data.forEach(client => {
              $this.clients[client.id] = { ...client, name: client.firstName + ' ' + client.lastName, buName: $this.getBusinessUnitById(client.businessUnitId).name, }
              return client.id
            })
            $this.validateClientsOrg()
            $this.loading = false
          })
    },
    validateClientsOrg(){
      console.log("validateClientsOrg", this.org)
      if(this.org == null){
        return;
      }
      let $this = this
      let clientsInOrg = (this.selected || []).filter(id => {
       let isValid =  this.clients[id] == null || this.getBusinessUnitById($this.clients[id].businessUnitId).orgId === this.org;
       if(!isValid){
         console.warn("Client is not in org", {org:this.org,client:this.clients[id],bu:this.getBusinessUnitById($this.clients[id].businessUnitId)})
       }
       return isValid;
      });
      if(this.selected.length > clientsInOrg.length){
        this.selected = clientsInOrg;
      }
      this.options = [];
      this.optionsPages = 0;
    },
    refreshOptions(onCompleted) {
      let $this = this
      console.log('refreshOptions', $this.searchInput)
      let request = {
        filter: { search: $this.searchInput },
        pagination:  { page: $this.optionsPage, size: $this.paginationSize }
      };
      if($this.org != null){
        request.filter.orgIds = [$this.org]
      }
      this.getAllClients(request)
          .then(clients => {
            this.optionsPages = clients.pagination.pages
            this.options = clients.data.map(client => {
              $this.clients[client.id] = { ...client, name: client.firstName + ' ' + client.lastName, buName: $this.getBusinessUnitById(client.businessUnitId).name, }
              return client.id
            })
          })
          .finally(() => {
            if (onCompleted) {
              onCompleted()
            }
            $this.loading = false
          })
    },
    updatePage(page) {
      this.loading = true
      this.optionsPage += page;
      this.refreshOptionsDebounce(() => this.loading = false)
    },
    onSearch(search, loading) {
      loading(true)
      console.log('onSearch', search)
      this.optionsPage = 1;
      this.searchInput = search
      this.refreshOptionsDebounce(() => loading(false))
    }
  }
}
</script>

<style scoped>

</style>
